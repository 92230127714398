import "slick-carousel";
import "slick-carousel/slick/slick.css";

import $ from "jquery";

var $slider = $(".slider"),
  $logo = $(".logo");

$slider.slick({
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  dots: false,
  infinite: true,
  speed: 500,
  fade: true,
  pauseOnHover: false,
  cssEase: "linear",
  nextArrow:
    '<button class="slick-next"><svg class="w-4 h-8 block fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.65 26.06"><path d="M.02 0S-.02 3.44.01 5.07c.01.32.2.76.42.99v.01l6.74 6.97L.45 20v.01c-.22.23-.42.67-.42.99-.03 1.62.01 5.07.01 5.07l3.37-3.48 9.24-9.55-.25-.24-9-9.31L.02 0z"/></svg></button>',
  prevArrow:
    '<button class="slick-prev"><svg class="w-4 h-8 block fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.65 26.06"><path d="M12.62 26.06s.04-3.44.01-5.07c-.01-.32-.2-.76-.42-.99v-.01l-6.74-6.97 6.73-6.96v-.01c.22-.23.42-.67.42-.99.03-1.62-.01-5.06-.01-5.06L9.24 3.48 0 13.03l.25.24 9 9.31 3.37 3.48z"/></button>'
});
